<template>
  <div v-loading="promotes.loading">
    <h4 class="modal_title" v-if="promotes.vip_card_count >0">以下会员卡可用</h4>
    <el-row :gutter="15">

      <el-col :span="col_span" v-for="vip_card in promotes.vip_card">

        <VipCard :vip_card="vip_card" :btn_buy="btn_buy"
                 @buy-vip-card="buy_vip_card(vip_card.uuid)"
                 @show-detail-modal="showDetailModal('vip', vip_card)"></VipCard>

      </el-col>

    </el-row>


    <h4 class="modal_title" style="margin-top:20px;" v-if="promotes.coupons_count >0">以下优惠券可用</h4>
    <el-row :gutter="15">

      <el-col :span="col_span" v-for="coupons in promotes.coupons"
              :style="{'display': (coupons.user_limit > 0 && coupons.user_issued >= coupons.user_limit && coupons.user_issued == coupons.user_used) ? 'none' : 'block'}">

        <Coupons :coupons="coupons" :btn_issue="btn_buy"
                 @issue-coupons="issue_coupons(coupons.uuid)"
                 @show-detail-modal="showDetailModal('coupons', coupons)"></Coupons>

      </el-col>

    </el-row>

    <!-- 详情框 -->
    <DiscountDetailModal :title="detailModal.title"
                         v-model:visible="detailModal.visible"
                         :type="detailModal.discountType"
                         :data="detailModal.data"></DiscountDetailModal>
  </div>
</template>

<script>
  import DiscountDetailModal from "components/settlement/DiscountDetailModal"
  import VipCard from "components/settlement/VipCard"
  import Coupons from "components/settlement/Coupons"
  import { listPromotes  } from 'api/settlement/cur_util'
  import { fmtVIPCardInfo } from 'api/settlement/vip_card/admin'
  import { fmtCouponsInfo } from 'api/settlement/coupons/admin'
  import { issueCur } from 'api/settlement/coupons/user'
  import { GetUserState, LoginUser, LogOutUser } from 'api/user/auth/login'
  import { prepareOrder } from 'api/settlement/order/user'
  import { ElMessage } from 'element-plus'
  import {getVipCardInstance, getCouponsInstance} from 'api/settlement/center_card'
  export default {
    components: {
      DiscountDetailModal,
      VipCard,
      Coupons
    },
    props: {
      btn_buy: {
        type: Boolean,
        default: true
      },
      col_span: {
        type: Object,
        default: 12
      },
      cur_uuid: {
          type: String,
          default: null,
      }
    },
    data() {
      return {
        detailModal: {
          visible: false,
          title: '',
          discountType: 'vip',
          data: {}
        },
        promotes: {
            loading: false,
            coupons: [],
            coupons_count: 0,
            vip_card: [],
            vip_card_count: 0,
        }
      }
    },

    mounted() {

      this.uuid = this.cur_uuid;

      if (this.$route.params.uuid) {
          this.uuid = this.$route.params.uuid ;
      }


      this.loadCurPromotes()
    },

    methods: {

      loadCurPromotes(){

        const that = this;

        listPromotes(this.uuid).then(result => {

          if (result.succeed) {

            let vip_card_list = [];
            result.vip_card.forEach(item => {
              let filled_vip_card = fmtVIPCardInfo(item);
              vip_card_list.push(filled_vip_card);
            });

            that.promotes.vip_card = vip_card_list;
            that.promotes.vip_card_count = vip_card_list.length;

            let coupons_list = [];
            result.coupons.forEach(item => {
              let filled_coupons = fmtCouponsInfo(item);
              coupons_list.push(filled_coupons);

              console.log(filled_coupons.uuid, filled_coupons.assets_limit, filled_coupons.user_limit,
                filled_coupons.available_num, filled_coupons.user_issued, filled_coupons)
            });

            that.promotes.coupons = coupons_list;
            that.promotes.coupons_count = coupons_list.length;

          } else {
            console.log(`${result.error_msg}`);
          }

        }).catch(err => {
          console.error(err);
        });

      },
      async showDetailModal(type, data) {

        switch (type) {
          case 'vip': {

            let filled_vip = await getVipCardInstance(data.uuid, () => {
                this.promotes.loading = true;
            }, () => {
                this.promotes.loading = false;
            });
            console.log(`filled_vip: `, filled_vip);

            data = filled_vip;

            console.log(data);

            this.detailModal.discountType = 'vip';
            this.detailModal.title = `会员卡 ${data.name} `;

            this.detailModal.data = {
              name: data.name,
              desc: data.desc.trim().length === 0 ? '-' : data.desc.trim(),
              discount: data.type === "discount"? data.discount_text + "折": data.discount_text,
              promote_price: data.promote_price,
              origin_price: data.origin_price,
              expired_desc: data.expired_desc,
              rights_tips: data.rights_tips.trim().length === 0 ? '-' : data.rights_tips.trim(),
              receive_tips: data.receive_tips.trim().length === 0 ? '-' : data.receive_tips.trim(),
              instructions: data.instructions.trim().length === 0 ? '-' : data.instructions.trim(),
              cur_scope_detail: data.cur_scope_detail,
              cur_scope: data.cur_scope,
            }

          } break;
          case 'coupons': {

           let filled_coupons = await getCouponsInstance(data.uuid, ()=>{
               this.promotes.loading = true;
            }, ()=>{
               this.promotes.loading = false;
            });

            console.log(`filled_coupons: `, filled_coupons);

            data = filled_coupons;

              this.detailModal.discountType = 'coupons';
              this.detailModal.title = `优惠券 ${data.name} `;

            this.detailModal.data = {
              name: data.name,
              desc: data.desc.trim().length == 0 ? '-' : data.desc.trim(),
              discount: `${data.discount_strength}${data.discount_text},${data.discount_limit}`,
              expired_desc: data.expired_desc,
              user_limit: data.user_limit,
              rights_tips: data.rights_tips.trim().length == 0 ? '-' : data.rights_tips.trim(),
              receive_tips: data.receive_tips.trim().length == 0 ? '-' : data.receive_tips.trim(),
              instructions: data.instructions.trim().length == 0 ? '-' : data.instructions.trim(),
              cur_scope_detail: data.cur_scope_detail,
              cur_scope: data.cur_scope,
            }

          } break;
        }

        console.log(this.detailModal.data);

        this.detailModal.visible = true;
      },
      async buy_vip_card(vip_card_uuid){

        const that = this;
        await prepareOrder([{
          type: 'vip',
          res_uuid: vip_card_uuid
        }]).then(result => {

          if (result.succeed) {
            that.goPath(`/order/confirm/${result.confirm_key}`);
          } else {

            switch (result.error_code) {
              case -9999: {
                that.$emit('update:loginVisible', true);
              }
                break;
              default: {
                that.$alert(`${result.error_msg}`, '提示', {
                  confirmButtonText: '确定'
                });
              }
                break;
            }


          }

        }).catch(err => {
          that.$alert(`${err}`, '提示', {
            confirmButtonText: '确定'
          });
        });

      },
      async issue_coupons(coupons_uuid){

        const that = this;

        issueCur(coupons_uuid).then(result => {

          if (result.succeed) {

            this.$notify({
              title: '领取成功',
              message: '优惠券已放入卡包~',
              type: 'success'
            });

            that.loadCurPromotes();

          } else {

            switch (result.error_code) {
              case -9999: {
                that.$emit('update:loginVisible', true);
              }
                break;
              default: {
                this.$notify({
                  title: '提示',
                  message: result.error_msg,
                  type: 'warning'
                });
              }
                break;
            }

          }

        }).catch(err => {
          this.$notify({
            title: '提示',
            message: err,
            type: 'warning'
          });
        });

      },
      goPath(path) {
        this.$router.push(path);
      },


    }
  }
</script>

<style lang="scss" scoped>

</style>
