/**
 * 管理员统计相关 API
 */
import { fetch, post } from 'api/base/axios'
import { getOrderStateText, } from 'api/settlement/order/admin'

const URL = {
  totalUrl: '/stats/order/total/',
  listUrl: '/stats/order/stats/list/',

};

export function orderStatsList(order_date_range) {
  return post(URL.listUrl, {
    'stats_range': order_date_range
  })
}

export function orderTotal(form={}) {
  return fetch(URL.totalUrl, form)
}


export function fillOrderStatesDistributedInfo(data){

  let order_stats_info_dict= {};
  order_stats_info_dict['data'] = [];


  let count_total = 0;
  let amount_total = 0;
  for (let key in data){
    let stats_data = data[key];

    count_total += stats_data.order_count;
    amount_total += stats_data.order_amount;

  }

  for (let key in data) {
    let stats_data = data[key];
    stats_data['state'] = parseInt(key);

    let state_text = getOrderStateText(key);

    stats_data.state_text = state_text;
    // stats_data.count_percent = parseFloat(((stats_data.order_count / count_total) * 100).toFixed(2));
    // stats_data.amount_percent = parseFloat(((stats_data.order_amount / amount_total) * 100).toFixed(2));

    order_stats_info_dict['data'].push(stats_data);

  }

  order_stats_info_dict['amount_total'] = parseFloat(amount_total.toFixed(2));
  order_stats_info_dict['count_total'] = count_total;

  return order_stats_info_dict

}

export function convertPayedOrderGoodsDistributedInfo(data) {

  let count_total = data.cur_num + data.vip_num;
  let amount_total = data.cur_amount + data.vip_amount;

  let data_list = [
    {
      'name': '课程',
      'count': data.cur_num,
      'amount': data.cur_amount,
    },
    {
      'name': '会员卡',
      'count': data.vip_num,
      'amount': data.vip_amount,
    },
  ];

  return {
    count_total: count_total,
    amount_total: amount_total,
    data: data_list
  };

}




